<template>
  <section class="pa-2">
    <v-row justify="center" align="center">
      <v-col v-if="$store.state.user.role == 'admin'" cols="12" md="12">
        <AgentStats />
      </v-col>
    </v-row>
  </section>
</template>

<script>
import AgentStats from "@/components/tables/agent-stats";
export default {
  components: {
    AgentStats,
  },
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>
