<template>
  <v-card>
    <v-card-title style="background-color: #80808029">
      Register {{ is_agent ? "Agent" : "Admin" }}
      <v-spacer></v-spacer>
      <v-btn v-if="!is_page" @click="$emit('close-dialog')" icon color="error">
        <v-icon> mdi-close </v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-form @submit.prevent v-model="isValidForm" ref="form">
        <v-form-base
          :col="{ cols: 12 }"
          :model="form_data"
          :schema="form_structure"
        />
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-btn block color="primary" @click="submit()"> Register </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import VFormBase from "vuetify-form-base";
import { mapState } from "vuex";
import axios from "axios";
import {
  required,
  validEmail,
  minLen,
  maxLen,
  validUsername,
} from "@/plugins/validationRules.js";

export default {
  components: { VFormBase },
  props: {
    is_agent: { required: true, default: true },
    is_page: { required: true, default: true },
  },
  data: () => {
    return {
      isValidForm: false,
      form_data: {
        name: null,
        username: null,
        email: null,
        password: null,
        image: null,
      },
    };
  },
  computed: {
    form_structure() {
      return {
        name: {
          type: "text",
          name: "name",
          label: "Name",
          clearable: true,
          col: { cols: 12, sm: 12, md: 12, lg: 12, xl: 12 },
          rules: [required("Name is required."), minLen(3)],
        },
        username: {
          type: "text",
          name: "username",
          label: "Username",
          clearable: true,
          tooltips: "Tooltips.",
          col: { cols: 12, sm: 12, md: 12, lg: 12, xl: 12 },
          rules: [
            required("Username is required."),
            validUsername("Invalid Username."),
          ],
        },
        email: {
          type: "text",
          name: "email",
          label: "Email",
          clearable: true,
          col: { cols: 12, sm: 12, md: 12, lg: 12, xl: 12 },
          rules: [validEmail("Must be a valid Email."), minLen(3)],
        },
        password: {
          type: "password",
          name: "password",
          label: "Password",
          clearable: true,
          col: { cols: 12, sm: 12, md: 12, lg: 12, xl: 12 },
          rules: [required("Password is required."), minLen(8)],
        },
        // image: {
        //   type: "text",
        //   name: "image",
        //   label: "Username",
        //   clearable: true,
        //   col: { cols: 12, sm: 12, md: 12, lg: 12, xl: 12 },
        //   rules: [required("Username or Email is required."), minLen(3)],
        // },
      };
    },
  },
  watch: {},
  mounted() {},
  methods: {
    submit() {
      var $this = this;
      this.$refs.form.validate();
      if (!this.isValidForm) return;
      let data = this.form_data;
      this.post(
        `${$this.baseUrl}/${
          this.is_agent ? "register-agent" : "register-admin"
        }`,
        data,
        true
      ).then((data) => {
        if (data == "undefined") return;
        this.$notify({
          group: "msg",
          title: data.error_obj ? data.message : "Message",
          text: data.error_obj
            ? Object.values(data.error_obj)[0][0]
            : data.message,
          type: data.status,
        });
        if (data.code == 200) {
          $this.$emit("registered", data);
          if ($this.is_page) {
            // $this.$router.replace({ name: "dashboard-home" });
          }
        }
      });
    },
  },
};
</script>

<style lang="scss">
</style>
