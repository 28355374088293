<template>
  <div>
    <base-material-card color="primary" class="px-5 py-3">
      <template v-slot:heading>
        <div class="display-1 font-weight-light">Agents Stats</div>
        <div class="subtitle-2 font-weight-light">
          All Agents Stats And Details
        </div>
      </template>
      <v-card-title>
        <v-btn outlined color="primary" @click="registrationDialog = true">
          Create
        </v-btn>
        <v-spacer></v-spacer>
        <v-text-field
          append-icon="mdi-magnify"
          v-model="search"
          label="Search"
          hide-details
          single-line
          clearable
        ></v-text-field>
      </v-card-title>
      <v-card-text>
        <v-data-table :headers="headers" :items="stats" :search="search">
          <template v-slot:item.applications="{ item }">
            {{ item.applications ? item.applications : "N/A" }}
          </template>
          <template v-slot:item.today_applications="{ item }">
            {{ item.today_applications ? item.today_applications : "N/A" }}
          </template>
          <template v-slot:item.actions="{ item }">
            <v-btn
              icon
              color="success"
              v-if="item.is_active"
              @click="inactiveAgentAccount(item)"
              title="Click to Inactive agent account."
            >
              <v-icon>mdi-account-check</v-icon>
            </v-btn>
            <v-btn
              icon
              color="orange"
              v-else
              @click="activeAgentAccount(item)"
              title="Click to Active agent account."
            >
              <v-icon>mdi-account-alert</v-icon>
            </v-btn>
            <v-btn
              icon
              color="error"
              @click="deleteAgentAccount(item)"
              title="Click to Delete agent account."
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
            <v-btn icon color="primary">
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </base-material-card>
    <v-dialog
      @click:outside="registrationDialog = false"
      v-model="registrationDialog"
      max-width="700px"
      persistent
    >
      <v-card>
        <register
          @registered="getAgentStats()"
          @close-dialog="registrationDialog = false"
          :is_agent="true"
          :is_page="false"
        />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import register from "@/components/forms/register";
export default {
  components: { register },
  data() {
    return {
      stats: [],
      registrationDialog: false,
      search: null,
      headers: [
        {
          sortable: false,
          text: "ID",
          value: "id",
          align: "left",
        },
        {
          sortable: true,
          text: "Name",
          value: "name",
          align: "left",
        },
        {
          sortable: true,
          text: "Total Applications",
          value: "applications",
          align: "left",
        },
        {
          sortable: true,
          text: "Today Applications",
          value: "today_applications",
          align: "left",
        },
        {
          sortable: false,
          text: "Actions",
          value: "actions",
          align: "right",
        },
      ],
    };
  },
  created() {
    this.getAgentStats();
  },
  methods: {
    async inactiveAgentAccount(agent) {
      if (!confirm("Agent Account Will Be Deactivated?")) return false;
      this.registrationDialog = false;
      return await this.post(
        this.baseUrl + `/inactive-agent-account`,
        { agent: agent.id },
        false
      ).then((data) => {
        if (data == "undefined") return;
        if (data.code == 200) {
          this.getAgentStats();
        }
      });
    },
    async activeAgentAccount(agent) {
      if (!confirm("Agent Account Will Be Activated?")) return false;
      this.registrationDialog = false;
      return await this.post(
        this.baseUrl + `/active-agent-account`,
        { agent: agent.id },
        false
      ).then((data) => {
        if (data == "undefined") return;
        if (data.code == 200) {
          this.getAgentStats();
        }
      });
    },
    async deleteAgentAccount(agent) {
      if (!confirm("Agent Account Will Be Deleted?")) return false;
      if (!confirm("Action is Irreversible.")) return false;
      this.registrationDialog = false;
      return await this.post(
        this.baseUrl + `/delete-agent-account`,
        { agent: agent.id },
        false
      ).then((data) => {
        if (data == "undefined") return;
        if (data.code == 200) {
          this.getAgentStats();
        }
      });
    },
    async getAgentStats() {
      this.registrationDialog = false;
      return await this.post(
        this.baseUrl + `/get-agents-stats`,
        {},
        false
      ).then((data) => {
        if (data == "undefined") return;
        if (data.code == 200) {
          this.stats = data.data;
        }
      });
    },
  },
};
</script>
